@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  font-family: 'Nunito', 'system-ui', sans-serif;
}

// Optionally, override Bootstrap's default font family
$font-family-base: 'Nunito', 'system-ui', sans-serif;

@import 'bootstrap.scss';
@import "BackgroundCubes.scss";



.navbar-brand{
  margin-right: 1.5rem!important;
}

.navbar-brand img, footer .brand-logo{
  max-width: 180px;
  max-height: 60px;
}

/* Top Navbar Menu */
.navbar{
  padding:1rem;
}

.envato-button:hover img, .envato-button:active img, .envato-button:focus img {
  filter: brightness(0) invert(1);
}

.bg-cover {
  background-size: cover;
}

.mask {
  position: absolute;
  background-size: cover;
  background-position: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
    //border-radius: .75rem;
}

hr.horizontal {
  background-color: transparent;
}

hr.horizontal.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .4), transparent);
}
hr {
  border-top: none !important;
  height: 1px;
}


.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  transform: scale(1.04);
  box-shadow: 0 6px 31px -2px rgba(0, 0, 0, 0.1);
}

.hvr-grow {
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.radial-gradient {
  position: absolute;
  left: -20%!important;
  top: -6rem!important;
  bottom: 0;
  width: 640px; /* Use percentage to create a more expansive effect */
  pointer-events: none; /* Prevent interference with user interactions */
  z-index: 0; /* Ensure it's behind other content */
  opacity: 35%;
}

.radial-gradient-left {
  left: 0;
  background-image: radial-gradient(50% 50% at 50% 50%, #ff6b00 0%, rgba(255, 255, 255, 0) 100%);

}

.radial-gradient-right {
  right: 0;
  background-image: radial-gradient(50% 50% at 50% 50%, #ff6b00 0%, rgba(255, 255, 255, 0) 100%);

}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.bg-body-secondary {
  background-color: rgb(244 244 244 / 70%) !important
}


.accordion-button:not(.collapsed) {
  //color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button.collapsed {
  //color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}

.z-index-m-1{
  z-index: -1;
}

.lh-1p6{
  line-height: 1.6rem!important;
}
