/* BackgroundCubes.css */

.background-cubes {
  //position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensures the cubes are behind your content */
  overflow: hidden;
}

/* Cube.css */

.cube {
  font-size: 50px; /* Adjust icon size */
  color: rgba(255, 255, 255, 0.3); /* Adjust color */
  position: absolute;
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
