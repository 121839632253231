// Define your custom theme colors
$min-contrast-ratio: 1;

$primary:       #ff6600 !default;
$secondary:     #ffa07a !default;
$light:         #ffffff !default;
$dark:          #333333 !default;
$info:          #17a2b8 !default;
$success:       #28a745 !default;
$warning:       #ffc107 !default;
$danger:        #dc3545 !default;

$fans: #CB0C9F !default;
$jobs: #F55536 !default;

@function color-contrast($color) {
  @return #ffffff; // Always return white
}

// Import Bootstrap after defining variables
//@import '~bootstrap/scss/bootstrap';

// Bootstrap components

@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

//

// Custom font size utilities
$custom-font-sizes: (
  'l': 3rem,
  'xl': 3.5rem,
  'xxl': 4rem,
  'xxxl': 4.5rem
);

@each $name, $size in $custom-font-sizes {
  .fs-#{$name} {
    font-size: $size !important;
  }
}

.btn, a {
  text-decoration: none !important;
}

// Custom padding utility classes that extend beyond Bootstrap's p-5

// Define the custom padding sizes you want to add
$custom-padding-sizes: (
  6: 3.5rem,   // Equivalent to p-6
  7: 4rem,     // Equivalent to p-7
  8: 4.5rem,   // Equivalent to p-8
  9: 5rem,     // Equivalent to p-9
  10: 5.5rem,  // Equivalent to p-10
  11: 6.5rem,  // Equivalent to p-11
  12: 7.5rem,  // Equivalent to p-12
  13: 8.5rem,  // Equivalent to p-13
  14: 9.5rem,  // Equivalent to p-14
  15: 10.5rem  // Equivalent to p-15
);

// Use Bootstrap's built-in breakpoints
@each $key, $value in $custom-padding-sizes {
  // Default padding classes (no breakpoint)
  .p-#{$key} {
    padding: $value !important;
  }
  .pt-#{$key} {
    padding-top: $value !important;
  }
  .pr-#{$key} {
    padding-right: $value !important;
  }
  .pb-#{$key} {
    padding-bottom: $value !important;
  }
  .pl-#{$key} {
    padding-left: $value !important;
  }
  .px-#{$key} {
    padding-left: $value !important;
    padding-right: $value !important;
  }
  .py-#{$key} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }

  // Responsive padding classes for each Bootstrap breakpoint
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .p-#{$breakpoint}-#{$key} {
        padding: $value !important;
      }
      .pt-#{$breakpoint}-#{$key} {
        padding-top: $value !important;
      }
      .pr-#{$breakpoint}-#{$key} {
        padding-right: $value !important;
      }
      .pb-#{$breakpoint}-#{$key} {
        padding-bottom: $value !important;
      }
      .pl-#{$breakpoint}-#{$key} {
        padding-left: $value !important;
      }
      .px-#{$breakpoint}-#{$key} {
        padding-left: $value !important;
        padding-right: $value !important;
      }
      .py-#{$breakpoint}-#{$key} {
        padding-top: $value !important;
        padding-bottom: $value !important;
      }
    }
  }
}

// Define custom rotation degrees for clockwise rotation
$rotation-degrees: (
  0: 0deg,
  1: 1deg,
  2: 2deg,
  3: 3deg,
  4: 4deg,
  5: 5deg,
  10: 10deg,
  15: 15deg,
  45: 45deg,
  90: 90deg,
  135: 135deg,
  180: 180deg,
  225: 225deg,
  270: 270deg,
  315: 315deg,
  360: 360deg
);

// Generate rotation utility classes for clockwise rotation
@each $key, $value in $rotation-degrees {
  .rotate-#{$key} {
    transform: rotate($value) !important;
  }
}

// Generate rotation utility classes for counterclockwise rotation
@each $key, $value in $rotation-degrees {
  .rotate-ccw-#{$key} {
    transform: rotate(-#{$value}) !important;
  }
}

// Avatar size utilities
.avatar-sm {
  width: 40px !important;
  height: 40px !important;
}

.avatar-md {
  width: 56px !important;
  height: 56px !important;
}

.avatar-lg {
  width: 64px !important;
  height: 64px !important;
}

.avatar-xl {
  width: 74px !important;
  height: 74px !important;
}

.avatar-xxl {
  width: 100px !important;
  height: 100px !important;
}

.avatar-xxxl {
  width: 120px !important;
  height: 120px !important;
}

// Utility for responsive sizes
@media (max-width: 576px) {
  .avatar-sm {
    width: 30px !important;
    height: 30px !important;
  }

  .avatar-md {
    width: 40px !important;
    height: 40px !important;
  }

  .avatar-lg {
    width: 50px !important;
    height: 50px !important;
  }

  .avatar-xl {
    width: 60px !important;
    height: 60px !important;
  }

  .avatar-xxl {
    width: 80px !important;
    height: 80px !important;
  }

  .avatar-xxxl {
    width: 100px !important;
    height: 100px !important;
  }
}

.avatar {
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: .75rem;
  height: 48px;
  width: 48px;
  transition: all .2s ease-in-out;
}


// Define your color map
$colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'warning': $warning,
  'info': $info,
  'light': $light,
  'dark': $dark
);

// Base gradient text utility
.text-gradient {
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

// Loop through each color in the map
@each $name, $color in $colors {
  // Ensure $color is treated as a color
  $color-value: if(type-of($color) == 'color', $color, unquote($color));

  // Normal gradient
  .text-gradient-#{$name} {
    background-image: linear-gradient(310deg, $color-value, lighten($color-value, 20%));
  }

  // Inversed gradient
  .text-gradient-#{$name}-inverse {
    background-image: linear-gradient(310deg, lighten($color-value, 20%), $color-value);
  }
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Gradient background utilities for Bootstrap's main theme colors
@each $color-name, $color-value in (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark
) {
  .bg-gradient-#{$color-name} {
    @if $color-name == "light" {
      background-image: linear-gradient(310deg, $color-value, darken($color-value, 20%));
    } @else {
      background-image: linear-gradient(310deg, $color-value, lighten($color-value, 20%));
    }
  }

  .bg-gradient-inverse-#{$color-name} {
    @if $color-name == "light" {
      background-image: linear-gradient(310deg, darken($color-value, 20%), $color-value);
    } @else {
      background-image: linear-gradient(310deg, lighten($color-value, 20%), $color-value);
    }
  }
}

// Gradient background utilities for colors from the $colors map
@each $color-name, $color-value in $colors {
  .bg-gradient-#{$color-name} {
    @if $color-name == "light" {
      background-image: linear-gradient(310deg, $color-value, darken($color-value, 20%));
    } @else {
      background-image: linear-gradient(310deg, $color-value, lighten($color-value, 20%));
    }
  }

  .bg-gradient-inverse-#{$color-name} {
    @if $color-name == "light" {
      background-image: linear-gradient(310deg, darken($color-value, 20%), $color-value);
    } @else {
      background-image: linear-gradient(310deg, lighten($color-value, 20%), $color-value);
    }
  }
}



// z-index utilities
.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.icon-shape {
  width: 48px;
  height: 48px;
  background-position: 50%;
  border-radius: .75rem;
}


// Icons
$icon-sizes: (
  xs: 24px,
  sm: 32px,
  md: 48px,
  lg: 64px,
  xl: 80px,
  hg: 96px
);

@each $name, $size in $icon-sizes {
  .icon-#{$name} {
    width: $size;
    height: $size;
  }
}


// Width Utilities generated in a loop
@for $i from 1 through 10 {
  $size: $i * 8; // Adjust the multiplier as per your requirements
  .width-#{$size}-px {
    width: #{$size}px;
  }
}


// Inner shadow utility classes
// Inner shadow utility classes with shadows starting from the bottom
$inner-shadows: (
  "sm": (0 3px 3px rgba(0, 0, 0, 0.12) inset, 0 2px 2px rgba(0, 0, 0, 0.24) inset),
  "md": (0 6px 6px rgba(0, 0, 0, 0.1) inset, 0 4px 4px rgba(0, 0, 0, 0.1) inset),
  "lg": (0 15px 15px rgba(0, 0, 0, 0.1) inset, 0 6px 6px rgba(0, 0, 0, 0.1) inset),
  "xl": (0 25px 25px rgba(0, 0, 0, 0.1) inset, 0 10px 10px rgba(0, 0, 0, 0.04) inset),
  "2xl": (0 50px 50px rgba(0, 0, 0, 0.15) inset)
);

@each $size, $value in $inner-shadows {
  .shadow-inner-#{$size} {
    box-shadow: $value;
  }
}
